<template>
	<Toast />
	<div class="p-grid crud-demo card" style="margin-bottom: 10px;">
		<div class="p-col-12">
			<!-- <div class="card" style="margin: -0.5em 0 -0.5em 0;"> -->
			<!-- <div> -->
				<Toolbar class="p-mb-4" style="display: flex;flex-wrap: wrap;justify-content:space-between;">
					<template v-slot:left>
						<Button label="新增" icon="pi pi-plus" :disabled="total_size>allow_size" class="p-button-success p-mr-2" @click="show_add_paper_dialog=true" title='新增文献' />
						<Button label="标注" icon="pi pi-pencil" class="p-button-secondary p-mr-2" :disabled="!selectedProducts || !selectedProducts.length" @click="editTopicsProductDialog = true" title='批量标注文献' />
						<Button icon="pi pi-trash" class="p-button-danger p-mr-2" @click="confirmDeleteSelected"
							:disabled="!selectedProducts || !selectedProducts.length" title='删除文献' />
						<Button icon="pi pi-upload" class="p-button-help p-mr-2"
							@click="exportCSV($event)" title='导出文献目录到本地' />
						<Button icon="pi pi-cloud-upload" class="p-d-inline-block" @click="updatePapers" title='保存修改到云端' />
					</template>

					<template v-slot:right>
						<div :title="'当前占用空间'+total_size+'M，总空间：'+allow_size+'M，占比：'+knobValue+'%'" class="percentage-container">
							<Knob v-model="knobValue" :step="1" :min="0" :max="100" valueTemplate="{value}%" />
							<p style="margin: auto;">{{total_size+'/'+allow_size+'M'}}</p>
						</div>
						<div v-if="valid_day!='永久'" style="margin-right: -60px;" :title="'套餐有效期至'+valid_day+'，使用时间占比：'+sliderValue+'%'" class="percentage-container">
							<Knob v-model="sliderValue" :step="1" :min="0" :max="100" valueTemplate="{value}%" />
							<p style="margin-left:-30px;">{{valid_day}}</p>
							<!-- <p style="margin-left: 10px;">时间占用</p> -->
						</div>
						<Button label="扩容续费" style="margin-left: 30px;" class="p-button-primary" @click='show_buy_part=true' title='扩容空间与续时间' />
						<Button icon='pi pi-globe' style="margin-left: 10px;" class="p-button-primary" @click='checkPayScan' title='手动刷新以同步个人套餐资源' />
					</template>
				</Toolbar>
				<!-- 筛选框 -->
				<DataTable ref="dt" :value="products" v-model:selection="selectedProducts" dataKey="id"
					:paginator="true" :rows="25"
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					:rowsPerPageOptions="[5,10,25,50]"
					currentPageReportTemplate="展示 {first} 至 {last} 共 {totalRecords} 篇文献"
					responsiveLayout="scroll" v-model:filters="filters1" filterDisplay="menu" :loading="processing"
					:filters="filters1"
					:globalFilterFields="['or','country.name','representative.name','balance','status']">

					<template #header>
						<pageOperator ref='pageOperate' :operate_list_copy='words_list_status' :this_operate_copy='this_status' 
						 @startSearch='getPersonalPaper' @initPage='initPage' @initFilters1='initFilters1' />
						 <div style="margin-top: 0.625rem;">注：2022年1月15日后上传的文档不再分页加载，超过50页的文档不再解析</div>
					</template>

					<template #empty>
						没有找到符合条件的文献
					</template>
					<template #loading>
						加载文献中,请稍后...
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					
					<!-- 原文 -->
					<Column field="pdf_name" header="文件名NAME" style="min-width:24rem">
						<template #body="{data}">
							<span class="p-column-title">NAME</span>
							<div title='点击查看文档' class='paper-name' @click="goToReadPaperPage(data)">
								{{data.pdf_name}}
							</div>
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter"
								placeholder="Search by name" />
						</template>
					</Column>

					<Column field="wl" filterField="select_topic.name" header="主题TOPIC" style="min-width:14rem">
						<template #body="{data}">
							<span class="p-column-title">TOPIC</span>
							<Dropdown v-model="data.select_topic" :options="data.topics" optionLabel="name" placeholder="TOPIC" />
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter"
								placeholder="Search by name" />
						</template>
					</Column>
					
					<Column field="paper_size" filterField="paper_size" :sortable="true" header="大小" style="min-width:8rem">
						<template #body="{data}">
							<span class="p-column-title">SIZE</span>
							<div v-if="data.paper_size!=0">
								{{data.paper_size}}M
							</div>
							<div v-if="data.paper_size===0" title='若文档长期处于此状态,说明解析失败,请删除后重新尝试上传!'>
								文档解析中...
							</div>
						</template>
					</Column>
					
					<Column field="status" header="状态" filterField="status" :showFilterMatchModes="false"
						:filterMenuStyle="{'width':'8rem'}" style="min-width:6rem">
						<template #body="{data}">
							<span class="p-column-title">状态</span>
							<span :class="'product-badge status-' + data.status">{{data.status}}</span>
						</template>
						<template #filter="{filterModel}">
							<div class="p-mb-3 p-text-bold">Status Picker</div>
							<Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any"
								class="p-column-filter" :showClear="true">
								<template #value="slotProps">
									<span :class="'product-badge status-' + slotProps.value"
										v-if="slotProps.value">{{slotProps.value}}</span>
									<span v-else>{{slotProps.placeholder}}</span>
								</template>
								<template #option="slotProps">
									<span
										:class="'product-badge status-' + slotProps.option">{{slotProps.option}}</span>
								</template>
							</Dropdown>
						</template>
					</Column>

					<Column header="更新日期" filterField="date" field="updated_time" dataType="date"
						:filterMenuStyle="{'width':'10rem'}" style="min-width:8rem">
						<template #body="{data}">
							<span class="p-column-title">日期</span>
							{{formatDate(data.date)}}
						</template>
						<template #filter="{filterModel}">
							<Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
						</template>
					</Column>

					<Column :filterMenuStyle="{'width':'10rem'}" style="min-width:8rem">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
								@click="editProduct(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning"
								@click="confirmDeleteProduct(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<!-- //编辑对象 -->
				<Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="修改文章主题" :modal="true"
					class="p-fluid">
					<div class="p-formgrid p-grid">
						<div class="p-field p-col-3">
							<Button label="文件名" class="p-button-secondary" />
						</div>
						<div class="p-field p-col-9">
							<InputText :disabled='true' id="or" v-model.trim="product.pdf_name" placeholder="输入原文" />
						</div>
					</div>
					<div class='card-gray'>
						<div class="p-formgrid p-grid"
							style="font-weight: 700;padding: 10px 0 10px 0;background-color:#f8f9fa;">
							<div class='p-col-8'>主题TOPIC</div>
							<div class='p-col-4'>操作</div>
						</div>
						<div class="p-formgrid p-grid translation-item"
							v-for="(item, j) of product.topics" :key="j">
							<div class='p-col-8'>{{item.name}}</div>
							<div class='p-col-2'><Button :disabled='product.topics.length==1' icon="pi pi-trash"
									class="p-button-text" @click="deleteTransIndex(j)" /></div>
							<div class='p-col-2'><Button  icon="pi pi-arrow-up"
									class="p-button-text"  @click="upTopic(j)" v-if='j>0' /></div>
							
						</div>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col-9">
							<InputText id='new_trans' v-model="new_trans" v-on:keyup.enter="addNewTranslatinToProduct"
								placeholder="输入主题TOPIC" />
						</div>
						<div class="p-field p-col-3">
							<Button label="添加" icon="pi pi-plus" class="p-button-info"
								@click="addNewTranslatinToProduct" />
						</div>
					</div>

					<template #footer>
						<Button label="取消" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
						<Button label="保存" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
					</template>
				</Dialog>
				<!-- 标注多篇文章的主题 -->
				<Dialog v-model:visible="editTopicsProductDialog" :style="{width: '450px'}" header="同时标注多篇文章的主题,空格分隔主题" :modal="true">
					<div class="confirmation-content" width='100%'>
							<WordFilterInput width='450px' ref='topicSelect' v-if='all_topics.length!==0' :list_copy='all_topics'
								:selected_words_copy='selected_topic_name' />
					</div>
					<template #footer>
						<Button label="取消" icon="pi pi-times" class="p-button-text"
							@click="editTopicsProductDialog = false" />
						<Button label="保存" icon="pi pi-check" class="p-button-text" @click="saveTopicForProducts" />
					</template>
				</Dialog>
				<!-- 删除所选单个项目的提示 -->
				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="提示" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem;" />
						<span v-if="product">删除后将无法恢复，仍然确定要删除<b> {{product.pdf_name}} </b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text"
							@click="deleteProductDialog = false" />
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
					</template>
				</Dialog>
				<!-- 删除所选多个项目的提示 -->
				<Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="重要提示" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">删除后将无法恢复，仍然确定要删除吗？</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text"
							@click="deleteProductsDialog = false" />
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
					</template>
				</Dialog>
				<!--上传文件的提示-->
				<uploadFile v-model:visible="show_add_paper_dialog" :upload_url_copy='upload_url'></uploadFile>
				<!-- 标注多篇文章的主题 -->
				<Dialog v-model:visible="show_buy_part" :style="{width: '450px'}" header="选择规格和时长扩容" :modal="true">
<!-- 					<div class="confirmation-content" width='100%'>
						规格与时长
					</div> -->
					<div class='p-grid' style="margin-top: 10px;">
						<div class='p-col-3'>
							<span style="color: #999999;">规格</span>
						</div>
						<div class="card" v-for="(item, j) of space_bags" :key="j">
							<Button v-if='select_space_id!=j' :label="item.name" class="no-buy-button" @click='selectSpace(j)' />
							<Button v-if='select_space_id==j' :label="item.name" class="buy-button" @click='selectSpace(j)' />
						</div>
					</div>
					<div class='p-grid' style="margin-top: 10px;">
						<div class='p-col-3'>
							<span style="color: #999999;">时长</span>
						</div>
						<div class='card ' v-for="(item, i) of time_bags" :key="i">
							<Button v-if='select_time_id!=i' :label="item.name" class="no-buy-button" @click='selectTime(i)'></Button>
							<Button v-if='select_time_id==i' :label="item.name" class="buy-button" @click='selectTime(i)' />
						</div>
					</div>
					<div class='p-grid'>
						<div class='p-col-3'>
							<span style="color: #999999;">规格说明</span>
						</div>
						<div class='p-col-9' style="font-size: 12px;color: #999999;">
							自购买之时起，时长<span style='color:#ff6000'>【{{time_bags[select_time_id].enlarge}}*30天】</span>内，您可以存储<span style='color:#ff6000'>【{{space_bags[select_space_id].name}}】</span>数据
						</div>
					</div>
					<template #footer>
						<div style="display: flex;flex-direction: column;float: right;margin-top: -10px;" v-if='allow_pay'>
							<div style="display: flex;align-items: center;">
								<span>资源包费用：<span style="color:#ff6000;font-size: 18px;">{{pay_price}}元</span></span>
								<Button label="立即购买" style="margin-left: 20px;" class="buy-button" @click='getPayQrcode' />
							</div>
							<div style="display: flex;align-items: center;margin-top: 10px;">
							<span style="color:#999999;font-size: 8px;float: right;">计费说明：原价 {{total_price}} 元，上一订单抵扣 {{off_price}} 元</span>
							</div>
						</div>
						<div style="display: flex;flex-direction: column;float: right;margin-top: -10px;" v-if='!allow_pay'>
							<span style="color:#999999;font-size: 8px;float: right;">上一订单可抵扣：{{off_price}} 元，大于本订单价格{{total_price}} 元，不可完成购买</span>
						</div>

					</template>
				</Dialog>
				<!--微信支付提示-->
				<Dialog v-model:visible="show_pay_qrcode" :style="{width: '330px'}" header="微信扫码付款" :modal="true">
					<div class="center-div" style="margin-top: -8px;margin-bottom: 10px;">
						<img src="../../../public/assets/layout/images/wxpay-logo.png" style="width: 60px;height: 60px;" alt="" />
						<span style="font-size: 22px;margin-left: 20px;">微信扫码完成支付</span>
					</div>
					<div class="center-div" style="background-color:#00b034;flex-direction: column;border-radius: 10px;margin-top: 10px;">
						<span style="color: #ffffff;font-size: 10px;margin-top: 20px;margin-bottom:-15px;">请确定收款方为“生活网平台” </span>
						<iframe :src="'qrcodejs/index.html?#####'+pay_code_url+'#####'+order_id+'##'+time_stamp+'##'+sign" frameborder="0" style="height: 280px;width:260px;"></iframe>
					</div>
					<div class="center-div">
					<!-- <span style="font-size: 10px;">*若付款后无法正常享受服务，请及时联系客服</span> -->
					<Button :label="'付款窗口将于'+qr_avaiable_time+'秒后自动关闭'"
						class="p-button-secondary p-button-text" />
					</div> 
				</Dialog>
				
			</div>
	</div>

</template>

<script>
	import {
		FilterMatchMode,
		FilterOperator
	} from 'primevue/api';
	import WordFilterInput from '../userComponents/WordFilterInput.vue';
	import pageOperator from '../userComponents/pageOperator.vue';
	import uploadFile from '../userComponents/uploadFile.vue';
	import NetWorkService from '../../service/NetWorkService.js';
	export default {
		name:'managePapers',
		data() {
			return {
				upload_url:"/api/pdf/upload",
				allow_pay:true,
				qr_avaiable_time: "120",
				// knobValue: 20,
				off_price:0,
				order_id:'',
				show_pay_qrcode:false,
				pay_code_url:'',
				total_price:0,
				pay_price:0,
				select_space_id:0,
				select_time_id:0,
				space_bags:[
					{
						'name':'500M',
						'size':500,
						'price':9,
					},
					{	
						'name':'1GB',
						'size':1000,
						'price':15,
					},
					{
						'name':'2GB',
						'size':2000,
						'price':25,
					},
					{
						'name':'4GB',
						'size':4000,
						'price':40,
					}
				],
				time_bags:[
					{
						'name':'1个月',
						'enlarge':1,
					},
					{	
						'name':'3个月',
						'enlarge':3,
					},
					{
						'name':'6个月',
						'enlarge':6,
					},
					{
						'name':'12个月',
						'enlarge':12,
					}
				],
				show_buy_part:false,
				show_add_paper_dialog:false,
				all_topics:[],
				selected_topic_name:'未标注',
				editTopicsProductDialog:false,
				total_size:0,
				allow_size:1,
				
				current_topics:[],
				topic:"all",
				
				words_list_status:[{
						'name': '所有文章',
						'button_class':'info',
						'status':'all',
						'id': '1'
					},
					{
						'name': '未标注',
						'button_class':'danger',
						'status':'untag',
						'id': '2'
					},
					{
						'name': '已标注',
						'button_class':'success',
						'status':'taged',
						'id': '3'
					}
				],
				this_status:{
						'name': '所有文章',
						'status':'all',
						'button_class':'info',
						'id': '1'
					},

				filters1: null,
				statuses: [
					'untag', 'taged'
				],

				new_trans: null,
				word_list: [],
				select_translation: null,
				products: null,
				productDialog: false,
				disabled_or: true,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: [],
				selectedProducts: null,
				filters: {},
				submitted: false,
				majors: [{
						'name': '测绘id',
						'id': '1'
					},
					{
						'name': '土木',
						'id': '0002'
					},
					{
						'name': '经管',
						'id': '0003'
					},
					{
						'name': '社创',
						'id': '0004'
					},
				],
				mj: {
					'name': '测绘',
					'id': '0001'
				}
				// mj:null,
			}
		},
		created() {
			this.networkService = new NetWorkService();
			this.initFilters1();
		},
		watch: {
			productDialog() {
				// console.log('当前productDialog值：',this.productDialog);
				if (this.productDialog == false) {
					this.product.topics=this.current_topics;
				}
			}
		},
		components: {
			'WordFilterInput': WordFilterInput,
			'pageOperator':pageOperator,
			'uploadFile':uploadFile,
		},
		computed:{
			valid_day(){
				if(this.$appState.user_info.space_size==200){
					return "永久"
				}
				// 根据毫秒数构建 Date 对象
				Date.prototype.toLocaleString = function() {
				          return this.getFullYear() + "-" + (this.getMonth() + 1) + "-" + this.getDate() +'  ' + this.getHours() + ":" + this.getMinutes() + ":" + this.getSeconds();
				    };
				var date = new Date(parseInt(this.$appState.user_info.current_order.valid_time)*1000);
				// 按重写的自定义格式，格式化日期
				var dateTime = date.toLocaleString();
				// console.log(dateTime);
				return dateTime;
			},
			knobValue(){
				var percentage=(this.total_size/this.$appState.user_info.space_size*100).toFixed(2);
				return percentage;
				// return 10;
			},
			sliderValue(){				
				if (this.$appState.user_info.space_size==200){
					return "0";
				}
				var current_second=new Date().getTime()/1000;
				var current_order=this.$appState.user_info.current_order;
				var percentage=(1-(current_order.valid_time-current_second)/(86400*30*current_order.valid_month))*100;
				if (percentage<0){
					return "0";
				}
				return percentage.toFixed(2);
			}
		},
		mounted() {
			this.allow_size=this.$appState.user_info.space_size;
			this.countTotalPrice();
			this.getPersonalPaper();
			// this.webSocketGetWxPayCallback();
			window.addEventListener('message', (e) => {
				// if (this.processing == true) {
				// 	return;
				// }
				var this_data = e.data;
				if (this_data.source!='uploadfile' && this_data.source!='wxpay'){
					return
				}
				// var content = this_data.content;
				if (this_data.source=='uploadfile'){
					if (this_data.code==200){
						this.$toast.add({
							severity: 'success',
							summary: '成功',
							detail: '文献上传完成，分析论文同步信息可能需要十几秒，请耐心等待',
							life: 3000
						});
						this.show_add_paper_dialog=false;
					}else{
						// this.show_add_paper_dialog=false;
						this.$toast.add({
							severity: 'error',
							summary: '上传失败',
							detail: this_data.content,
							life: 3000
						});
					}
				}else{
					// console.log(this_data);
					var resp=JSON.parse(this_data.resp);
					// console.log('成功传回支付回调',resp);
					if (resp.code==200){
						// console.log('成功传回支付回调',resp);
						this.$appState.user_info.current_order=resp.data.order_info;
						this.$appState.space_size=resp.data.order_info.space_size;
						this.show_pay_qrcode=false;
						this.processing=false;
						this.allow_size=resp.data.order_info.space_size;
						//清空计时器
						clearInterval(this.Timer);
						this.$toast.add({
							severity: 'success',
							summary: '成功',
							detail: '支付成功，当前套餐已更改',
							life: 5000
						});
					}
					if(resp.code==-1){
						//清空计时器
						this.show_pay_qrcode=false;
						clearInterval(this.Timer);
						this.$toast.add({
							severity: 'error',
							summary: '失败',
							detail: resp.msg,
							life: 5000
						});
					}
				}
			});
			//屏幕变化时
		},
		methods: {
			setTimer() {
				let holdTime = 119,
					_this = this;
				// _this.qr_avaiable_time = "300"
				_this.qr_avaiable_time = '119';
				_this.Timer = setInterval(() => {
					if (holdTime <= 0) {
						this.show_pay_qrcode=false;
						_this.qr_avaiable_time = "119";
						clearInterval(_this.Timer);
						
						this.$toast.add({
							severity: 'error',
							summary: '支付提示',
							detail: "支付窗口已关闭,完成支付后将需要手动刷新以更新资源套餐!",
							life: 3000
						});
						
						return;
					}
					_this.qr_avaiable_time = holdTime;
					holdTime--;
				}, 1000)
			},
			computeOffPercentage(){
				for (var i=0;i<this.time_bags.length;i++){
					var this_bag=this.time_bags[i];
					//定价，100M/3元
					var off_percentage=(this_bag.price/(this_bag.size/100*3)).toFixed(2);
					this_bag['off_percentage']=off_percentage;
					this.time_bags[i]=this_bag;
				}
			},
			async checkPayScan(){
				this.$appState.processing=true;
				var data = new URLSearchParams();
				var url = '/member/check-paystatus';
				var resp = await this.networkService.postService(url, data);
				if(resp.code==200){
					this.$appState.user_info.space_size=resp.data.current_order.space_size;
					this.allow_size=resp.data.current_order.space_size;
					this.$toast.add({
						severity: 'success',
						summary: '成功',
						detail: '当前资源套餐同步已完成',
						life: 3000
					});
				}
				this.$appState.processing=false;
				// console.log(resp);
			},
			async getPayQrcode(){
				if (this.pay_price<0){
					return;
				}
				if (this.pay_price<0.01){
					this.pay_price=0.01;
				}
				this.$appState.processing=true;
				var data = new URLSearchParams();
				data.append("space_size",this.space_bags[this.select_space_id].size);
				data.append("valid_time",this.time_bags[this.select_time_id].enlarge);
				// data.append("total_price",this.time_bags[this.select_time_id].enlarge);
				data.append("total_price",this.total_price);
				data.append("pay_price",this.pay_price);
				// data.append("pay_price",0.02);
				var url = '/member/get-payqrcode';
				var resp = await this.networkService.postService(url, data);
				if(resp.code==200){
					this.show_buy_part=false;
					this.pay_code_url=resp.data.code_url;
					this.order_id=resp.data.order_id;
					
					//获取sign
					this.time_stamp=this.networkService.getTimeStamp();
					this.sign=this.networkService.LL(this.time_stamp);
					this.show_pay_qrcode=true;
					this.qr_avaiable_time=120;
					
					var _this=this;
					clearInterval(_this.Timer);
					_this.setTimer();
					// setTimeout(() => {
					// 	_this.setTimer();
					// }, 0)
					//开启长链接接受微信支付回调
					// this.ws.send(resp.data.order_id);
					// this.webSocketGetWxPayCallback(resp.data.order_id);
				}else{
					this.show_buy_part=false;
					this.$toast.add({
						severity: 'error',
						summary: '失败',
						detail: '付款金额不符，请稍后再试！',
						life: 3000
					});
				}
				this.$appState.processing=false;
				// console.log(resp);
				// this.processing = false;
			},
			selectTime(i){
				this.select_time_id=i;
				this.countTotalPrice()
			},
			selectSpace(j){
				this.select_space_id=j;
				this.countTotalPrice()
			},
			countTotalPrice(){
				var space_bag=this.space_bags[this.select_space_id];
				var time_bag=this.time_bags[this.select_time_id];
				this.total_price=space_bag.price*time_bag.enlarge;
				
				var current_second=new Date().getTime()/1000;
				var current_order=this.$appState.user_info.current_order;
				if (current_order.valid_time<=current_second){
					var off_price=0;
				}else{
					off_price=(current_order.valid_time-current_second)/(86400*30*current_order.valid_month)*current_order.total_price-0.01;
				}
				// console.log('折扣价格',off_price);
				//使用不满一分按照一分计算
				// off_price=Math.floor(off_price*100)/100;
				// console.log('四舍五入折扣价格',off_price);
				this.off_price=off_price.toFixed(2);
				var pay_price=this.total_price-off_price;
				if (pay_price<0){
					this.allow_pay=false;
				}else{
					this.allow_pay=true;
				}
				this.pay_price=pay_price.toFixed(2);
			},
			goToReadPaperPage(item){
				if(item.paper_size==0){
					this.$toast.add({
						severity: 'error',
						summary: '打开文档失败',
						detail: '当前文档解析中，请等待解析完成后再查看',
						life: 3000
					});
					return
				}
				// console.log(item.pdf_src);
				this.$router.push({
					name: 'readPaper',
					params: {
					  pdf_path: item.pdf_src, 
					  current_page: 1,
					  total_page:0,
					  read_single_pdf:item.whole_file,
					  pdf_name:item.pdf_name,
					}
				})
			},
			getTopics() {
				//标注倒序并组织
				var str_topics = this.$refs.topicSelect.selected_words;
				if (str_topics == '') {
					str_topics = '未标注'
				}
				var words = str_topics.split(' ');
				var or_topics = words.reverse();
				var topics = [];
				for (var i = 0; i < or_topics.length; i++) {
					var this_id = or_topics[i];
					if (this_id != '') {
						topics.push({
							'name': this_id
						});
					}
				}
				return topics
			},
			//保存标注的主题
			saveTopicForProducts(){
				var topics=this.getTopics();
				for (var i=0;i<this.selectedProducts.length;i++){
					this.selectedProducts[i].topics=topics;
					this.selectedProducts[i].select_topic=topics[0];
				}
				this.editTopicsProductDialog=false;
				this.updatePapers();
			},
			//初始化页面参数
			initPage() {
				this.products = null;
				this.selectedProducts = null;
				this.product = {};
			},
			//格式化时间的格式
			formatDate(value) {
				return value.toLocaleDateString('en-US', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				});
			},
			//筛选的结构
			initFilters1() {
				this.filters1 = {
					'global': {
						value: null,
						matchMode: FilterMatchMode.CONTAINS
					},
					'pdf_name': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.STARTS_WITH
						}]
					},
					'select_topic.name': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.EQUALS
						}]
					},
					//日期筛选
					'date': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.DATE_IS
						}]
					},
					'status': {
						operator: FilterOperator.OR,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.EQUALS
						}]
					}
				}
			},
			addNewTranslatinToProduct() {
				if (!this.new_trans) return;
				var new_t = {
					'name': this.new_trans
				}
				if(this.product.topics[0].name=='未标注'){
					this.product.topics[0]=new_t;
					this.product.select_topic=new_t;
				}else{
					this.product.topics.push(new_t);
				}
				this.new_trans = '';
			},
			//调整topic顺序
			upTopic(index) {
				if (index == 0) {
					return;
				}
				var old_topic = this.product.topics[index - 1];
				this.product.topics[index - 1] = this.product.topics[index];
				this.product.topics[index] = old_topic;
			},
			deleteTransIndex(index) {
				this.product.topics.splice(index, 1);
			},
			showNoSelectFail() {
				this.$toast.add({
					severity: 'error',
					summary: '失败',
					detail: '请先勾选至少一个需操作对象',
					life: 3000
				});
			},
			async updatePapers() {
				// console.log(this.selectedProducts);
				if (!this.selectedProducts) {
					this.showNoSelectFail();
					return;
				}
				this.$appState.processing=true;
				this.processing = true;
				var data = new URLSearchParams();
				
				data.append("update_papers", JSON.stringify(this.selectedProducts));
				var url = '/pdf/update-papers';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				if (resp.code == 200) {
					this.showUpdateSuccessFully();
					this.reflushProduts();
				}
				this.selectedProducts = null;
				this.$appState.processing=false;
			},
			//获取个人文献表
			async getPersonalPaper() {
				this.$appState.processing=true;
				this.processing = true;
				var data = new URLSearchParams();
				//用结构化查询字典查询单词
				var search_data=this.$refs.pageOperate.getStructSearchData();
				data.append("search_data", JSON.stringify(search_data));
				var url = '/pdf/search-papers';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.products = resp.data.personal_papers;
				// console.log(this.products);
				this.$refs.pageOperate.total_page_num = resp.data.total_page;
				this.all_topics = resp.data.all_topics;
				this.total_size=resp.data.user_storage;
				this.createIdForAllProduts();
				this.processing = false;
				this.$appState.processing=false;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
				this.product.topics=this.current_topics;
				this.product={}
			},
			saveProduct() {
				this.submitted = true;
				if (this.product.topics.length == 0) {
					this.$toast.add({
						severity: 'error',
						summary: '保存失败',
						detail: '请添加至少一个主题',
						life: 3000
					});
					return;
				}
				this.product.select_topic = this.product.topics[0];
				this.products[this.findIndexById(this.product.id)] = this.product;
				//打印成功信息
				this.productDialog = false;
				//保存文件
				this.selectedProducts=[this.product];
				this.updatePapers();
				this.product = {};
			},
			editProduct(product) {
				//当取消时，重置翻译结果
				for (var i = 0; i < product.topics.length; i++) {
					this.current_topics.push(product.topics[i]);
				}
				//注明该词所属专业
				this.product = {
					...product
				};
				this.productDialog = true;
				this.disabled_or = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				this.deleteProductDialog = false;
				this.deletePapers([this.product.pdf_uuid]);
			},
			showUpdateSuccessFully() {
				this.$toast.add({
					severity: 'success',
					summary: '成功',
					detail: '更新选中文献成功',
					life: 3000
				});
			},
			//删除个人词表
			async deletePapers(pdf_uuids) {
				// console.log(this.selectedProducts);
				// this.processing = true;
				this.$appState.processing=true;
				var data = new URLSearchParams();
				data.append("pdf_uuids", JSON.stringify(pdf_uuids));
				var url = '/pdf/delete-papers';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				if (resp.code == 200) {
					this.showUpdateSuccessFully();
				}
				this.$appState.processing=false;
				// this.processing = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createIdForAllProduts() {
				//给所有词表生成本地id
				if (!this.products) {
					return;
				}
				for (var i = 0; i < this.products.length; i++) {
					this.products[i].id = this.createId();
					//新增date属性用于筛选
					this.products[i].date = new Date(this.products[i].updated_time);
				}
				
				// console.log(this.products);
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for (var i = 0; i < 5; i++) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				// console.log(this.selectedProducts);
				if (!this.products) {
					this.$toast.add({
						severity: 'error',
						summary: '导出失败',
						detail: '不存在可导出的对象',
						life: 3000
					});
					return;
				}
				this.joinWordToWL();
				this.$refs.dt.exportCSV(true);
				//删除wl属性
				for(var i=0;i<this.products.length;i++){
					delete this.products[i].wl;
					// console.log('删除成功');
				}
			},
			//将所选词表的单词合起来做成wl，便于导出
			joinWordToWL() {
				for (var i = 0; i < this.products.length; i++) {
					var this_product = this.products[i];
					var topics = this_product.topics;
					//该对象的词表
					var wl = topics[0].name;
					for (var j = 1; j < topics.length; j++) {
						var word = topics[j];
						wl += ',' + word.name;
					}
					//将构造的词表加入到总表中
					this.products[i]['wl'] = wl;
				}
				// console.log(this.products);
			},
			confirmDeleteSelected() {
				this.deleteProductsDialog = true;
			},
			//延迟2s刷新单词列表
			reflushProduts() {
				this.initPage();
				var that = this;
				setTimeout(function() {
					that.getPersonalPaper();
				}, 2000);
			},
			deleteSelectedProducts() {
				this.products = this.products.filter(val => !this.selectedProducts.includes(val));
				this.deleteProductsDialog = false;
				var pdf_uuids = [];
				for (var i in this.selectedProducts) {
					pdf_uuids.push(this.selectedProducts[i].pdf_uuid);
				}
				//删除云端对象
				if (pdf_uuids.length > 0) {
					this.deletePapers(pdf_uuids)
				} else {
					this.showUpdateSuccessFully();
				}
				this.selectedProducts = null;
			}
		}
	}
</script>

<style scoped lang="scss">
	.percentage-container{
		// margin-top: -40px;
		font-size: 20px;
		height: 50px;
		padding: 0;
		transform: scale(0.5,0.5);
		margin-right:-30px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		// background-color: red;
	}
	
	.manu-refresh{
		color: #ffffff;
		font-size: 10px;
		text-decoration:underline;
	}
	.manu-refresh:hover{
		font-weight: 600;
		color: #ffffff;
	}
	.center-div{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	//微信支付颜色#00b034
	.buy-button{
		background-color: #515e88;
		color: #ffffff;
		border: none;
		border-radius: 0;
	}
	.no-buy-button{
		background-color: #edeef3;
		color: #000000;
		border: none;
		border-radius: 0;
	}
	
	.translation-item {
		display: flex;
		align-items: center;
		border-top: 1px solid #ededed;
		padding: 2px 0px 2px 0px;
	}
	
	.paper-name {}
	
	.paper-name:hover {
		color: #1fa1fc;
		font-weight: 600;
	}

	.word-item {}

	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}

	.table-header {
		display: flex;
		justify-content: space-between;
	}

	.product-image {
		width: 100px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}

	.p-dialog .product-image {
		width: 150px;
		margin: 0 auto 2rem auto;
		display: block;
	}

	.confirmation-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.product-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-taged {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-untag {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-lowstock {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

	::v-deep(.p-toolbar) {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
	}
</style>
